<template>
    <div class="page-rooter-fff">
        <headBar ref="headerHeight" title="邀请有礼" left-arrow @click-left="newAppBack"/>
        <div class="page-h">
            <div class="user-info col-center-center">
                <img :src="headImg" alt="">
                <p class="bold">{{ nickName }}</p>
                <div v-if="[6, 8].includes(status)" class="tips-icon row-center-center">已领取</div>
                <div class="bold">送你60元小班课优惠券</div>
                <span v-if="expireTime">有效期：{{ expireTime }}</span>
            </div>
            <div class="left-btn">

            </div>
            <div class="gift-nav col-start-center">
                <div  @click="jumpApp" v-if="[6, 8].includes(status)" class="row-center-center">{{ bindTeacherId? '预约推荐教练': '预约推荐课程' }}</div>
                <div @click="acceptInvite" v-else class="row-center-center">立即领取</div>
                <p v-if="status === 3" class="bold active row-center-center">您已绑定邀请人<span class="ellipsis s1">{{ userName }}</span>，领券后不会更改绑定关系</p>
                <p v-else-if="[6, 8].includes(status)" class="bold active"><span class="ellipsis s2">{{ content }}</span></p>
                <p v-else class="row-center-center bold">{{ content }}</p>
            </div>
        </div>
        <div class="course-content">
            <img v-if="bindTeacherId" src="https://img.chaolu.com.cn/ACT/invite-2023/course-title.png" alt="">
            <img v-else-if="classId" src="https://img.chaolu.com.cn/ACT/invite-2023/course-title1.png" alt="">
            <div class="share-user-medal row-start-start">
                <img :src="teacherInfo.headImg || classInfo.headIcon" alt="">
                <div class="row-start-start" v-if="bindTeacherId">
                    <div>
                        <div class="use-header-name-medal ellipsis">{{ teacherInfo.name }}</div>
                        <div class="use-header-label">
                            <span class="ellipsis-3">{{teacherInfo.microIntroduction}}</span>
                        </div>
                    </div>
                </div>
                <div v-if="classId" class="course-box">
                    <h4>{{ classInfo.schemeTitle }}</h4>
                    <p>{{ classInfo.openDate }}<span>{{ classInfo.timeProfile }}</span></p>
                    <div class="row-start-end">
                        <div class="price row-start-end">
                            <span class="t1">¥</span>
                            <span class="t2">{{ classInfo.cost }}</span>
                            <span class="t3">/节</span>
                        </div>
                        <div class="row-start-center coach-name">
                            <span>{{ classInfo.mainTeacherName }}</span>
                            <span class="level">{{ classInfo.mainTeacherMicroLevelMsg }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="course-bottom-btn row-end-center">
                <div @click="jumpApp" class="row-center-center bold">立即预约</div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    newAppBack,
    initBack,
    gotoCampExclusiveDetail,
    defaultApp,
    webAppShare,
    gotoAppPage,
    gotoCoachDetail
} from '@/lib/appMethod';
import headBar from '@/components/app/headBar';
import userMixin from '@/mixin/userMixin';
import wx from "weixin-js-sdk";
export default {
    components: {
        headBar
    },
    mixins: [userMixin],
    name: "accept",
    data() {
        return {
            inviteUid: '',
            bindTeacherId: '',
            classId: '',
            status: '',
            expireTime: '',
            content: '',
            nickName: '',
            headImg: '',
            userName: '',
            teacherInfo: {},
            classInfo: {}
        }
    },
    // mounted() {
    //     document.addEventListener("visibilitychange",  () => {
    //         if (document.visibilityState === "visible") {
    //             if(this.userId){
    //                 this.acceptInviteCheck()
    //             }
    //         }
    //     })
    // },
    // beforeDestroy() {
    //     document.removeEventListener("visibilitychange",  () => {
    //         console.log('移除监听')
    //     })
    // },
    async created() {
        initBack();

        // 活动已经下线提示活动已结束
        this.$dialog.alert({
            message: ' <b>很抱歉，该活动已经结束！<br/>去看看超鹿其他的活动吧</b>',
            confirmButtonText: '我知道了',
            className: 'dialog',
            overlayStyle: {backgroundColor: 'rgba(0,0,0,1)'}
        }).then(() => {
            if(this.appTypeStr === 'mini'){
                wx.miniProgram.switchTab({
                    url: `/pages/home/index`
                })
            }else{
                this.appBack()
            }
        })
        return false
        this.inviteUid = this.$route.query.inviteUid || ''
        this.classId = this.$route.query.classId || ''
        this.bindTeacherId = this.$route.query.teacherId || ''
        await this.$getUserId();
        this.acceptInviteCheck()
        if(this.classId) {
            this.getClassInfo()
        }
        if(this.bindTeacherId) {
            this.getTeacher()
        }
    },
    methods: {
        newAppBack,
        acceptInviteCheck() {
            this.$axios.post(`${this.baseURLApp}/newMicro/invitation/acceptInviteCheck`,{
                "hasWx": true,
                "token": this.token,
                "userId": this.userId,
                referrerId: this.inviteUid,
                isNewUser: 0
            }).then(r => {
                this.status = r.data.status

                this.content = r.data.content
                this.userName = r.data.userName
                this.expireTime = r.data.expireTime
                this.nickName = r.data.inviterUserName
                this.headImg = r.data.inviterUserHeadPortrait || 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/WeChat/coach/logo.jpg'

                if(this.status === 2 || this.status === 5){
                    this.$dialog.alert({
                        title: '接受邀请失败',
                        message: this.content,
                        confirmButtonText: this.status === 2?'立即参加': '我知道了',
                        confirmButtonColor: '',
                        overlayStyle: {backgroundColor: 'rgba(0,0,0,1)'}
                    }).then(() => {
                        this.jump()
                    });
                }
                if(this.status === 6 || this.status === 3) {
                    let text = this.content
                    if(this.status === 3){
                        text = `您已绑定邀请人${ this.userName }，领券后不会更改绑定关系`
                    }
                    this.$dialog.alert({
                        title: '温馨提示',
                        message: text,
                        confirmButtonText: '我知道了',
                        confirmButtonColor: '',
                    }).then(() => {

                    });
                }
                if (this.status === 4) {
                    this.$dialog.alert({
                        title: '温馨提示',
                        message: `亲爱的续转会员，为了让您享受更优质的超鹿服务，我们为您独家升级新人邀请礼包。\n1、续转会员有效期内，邀请1位好友，最高可延长13天年卡权益；\n2、原有获得且未领取的128礼包仍可领取。\n 由此给您带来的不变，我们深感歉意。如有疑问，可拨打客服电话：\n 400-700-5678`,
                        confirmButtonText: '查看新的邀请奖励',
                        overlayStyle: {backgroundColor: 'rgba(0,0,0,1)'}
                    }).then(() => {
                        defaultApp();
                        if(this.appTypeStr === 'mini'){
                            this.$router.push("/emptyPage?isUndo=1")
                        }else{
                            window.location.href = `${this.baseURL}/index/identityInvite?userId=${this.userId}`
                        }

                    });
                    return false;
                }
                if (this.status === 7) {
                    this.$dialog.alert({
                        title: '接受邀请失败',
                        message:  this.content,
                        confirmButtonText: '我知道了',
                        overlayStyle: {backgroundColor: 'rgba(0,0,0,1)'}
                    }).then(() => {
                        this.jump()
                    });
                    return false;
                }

            })
        },
        acceptInvite() {
            this.$axios.post(`${this.baseURLApp}/newMicro/invitation/acceptInvite`,{
                "hasWx": true,
                "token": this.token,
                "userId": this.userId,
                referrerId: this.inviteUid,
                isNewUser: 0
            }).then(r => {
              this.status = r.data.status
              if (this.status === 8) {
                  this.$dialog.alert({
                      title: '温馨提示',
                      message: '你已领取小班课券，不可以重复领取哦',
                      confirmButtonText: '我知道了',
                      overlayStyle: {backgroundColor: 'rgba(0,0,0,1)'}
                  }).then(() => {

                  });
              }
              this.acceptInviteCheck()

            })
        },
        getTeacher() {
            this.$axios.post(`${this.baseURLApp}/micro/invite/get/teacher`,{
                "hasWx": true,
                "token": this.token,
                "userId": this.userId,
                id: this.bindTeacherId,
            }).then(r => {
                this.teacherInfo = r.data
            })
        },
        getClassInfo() {
            this.$axios.post(`${this.baseURLApp}/micro/invite/get/clazz`,{
                "hasWx": true,
                "token": this.token,
                "userId": this.userId,
                id: this.classId,
            }).then(r => {
                this.classInfo = r.data
            })
        },
        jump(path = 'superdeer-activity/invite-2023', parma = '') {
            // superdeer-activity/invite-2023  邀请页
            if (this.appTypeStr === 'mini') {
                wx.miniProgram.redirectTo({
                    url: `/pages/webView/index?webUrl=${window.location.origin}/#/${path}&userId=1&token=1&location=1${parma}`,
                })
            } else {
                this.$router.replace({path: `/${path}?userId=1&token=1&location=1&cityId=1${parma}`})
            }
        },
        jumpApp() {
            if(this.classId){
                gotoAppPage(11, this.classId)
            }else if(this.bindTeacherId){
                gotoCoachDetail(this.bindTeacherId)
            }
        },
    }
}
</script>

<style scoped lang="less">
.page-rooter-fff{
    min-height: 100vh;
    background-color: #FFEBDE;
    padding-bottom: 90px;
    box-sizing: border-box;
}
.page-h{
    width: 750px;
    height: 784px;
    background: url("https://img.chaolu.com.cn/ACT/invite-2023/accept_bg.png") center center no-repeat;
    background-size: 100% 100%;
    position: relative;
    .user-info{
        padding-top: 30px;
        position: relative;
        .tips-icon{
            position: absolute;
            top: 282px;
            right: 174px;
            width: 114px;
            height: 50px;
            background: linear-gradient(90deg, #FF7A00 0%, #FF4C24 100%);
            border-radius: 24px 32px 32px 4px;
            line-height: 26px;
            font-weight: bold;
            font-size: 26px;
            color: #FFFFFF;
            margin-top: 0;
        }
        img{
            width: 104px;
            height: 104px;
            border: 4px solid #FFF6F0;
            border-radius: 50%;
            box-sizing: border-box;

        }
        p{
            margin-top: 20px;
            line-height: 26px;
            font-size: 26px;
            color: #242831;
            height: 26px;
        }
        div{
            margin-top: 24px;
            font-size: 32px;
            color: #761624;
            line-height: 32px;
            height: 32px;
        }
        span{
            margin-top: 204px;
            color: #761624;
            line-height: 24px;
            font-size: 24px;
            text-align: center;
        }
    }
    .gift-nav{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 750px;
        height: 244px;
        div{
            width: 440px;
            height: 108px;
            font-family: PuHuiTi;
            color: #E22C18;
            font-size: 44px;
            background: url("https://img.chaolu.com.cn/ACT/invite-2023/accept_btn.png") no-repeat center center;
            background-size: 100% 100%;
        }
        p{
            color: #FCEED5;
            line-height: 26px;
            font-size: 26px;
            margin-top: 36px;
            text-align: center;
            &.active{
                margin-top: 18px;
                background: linear-gradient(90deg, #F4FC99 0%, #85F5E1 100%);
                color: #242831;
                padding: 0 22px;
                height: 52px;
                border-radius: 26px;
                font-size: 24px;
                position: relative;
                line-height: 54px;
                white-space: nowrap;
                .s1{
                    max-width: 6em;
                    display: inline-block;
                    vertical-align: middle;
                }
                .s2{
                    max-width: 620px;
                    display: inline-block;
                }
                &:after{
                    content: '';
                    width: 20px;
                    height: 20px;
                    background-color: #BCF8BD;
                    transform: rotateZ(45deg);
                    position: absolute;
                    top: -6px;
                    left: calc(50% - 10px);
                }
            }
        }
    }
}
.share-user-medal{
    display: flex;
    padding: 0 32px;
    box-sizing: border-box;
    img{
        width: 152px;
        height: 152px;
        border-radius: 17px;
        margin-right: 24px;
    }
    .use-header-name-medal{
        //margin-left: 16px;
        font-size: 28px;
        font-weight: bold;
        color: #AA3E1E;
    }
    .poster-code-cont-medal{
        width: 150px;
        height: 150px;
        border-radius: 50%;
        box-sizing: border-box;
        flex-shrink: 0;
        img{
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 50%;
        }
    }
    .use-header-label{
        margin-top: 14px;
        flex-wrap: wrap;
        font-size: 20px;
        border-radius: 6px;
        background: #FFFAF8;
        padding: 8px 12px;
        border: 2px solid #FAE4D5;
        line-height: 30px;
        max-width: 438px;
        box-sizing: border-box;
        span{
            color: #761624;
        }
    }
    .course-box{
        h4{
            line-height: 28px;
            font-size: 28px;
            font-weight: bold;
            color: #AA3E1E;
            padding-top: 8px;
        }
        &>div{
            margin-top: 24px;
            font-size: 24px;
            font-weight: 400;
            line-height: 24px;
            color: #3C454E;
            .coach-name{
                //transform: translateY(2px);
            }
            .level{
                margin-left: 10px;
                font-size: 20px;
                line-height: 20px;
                font-weight: bold;
                background: #3C454E;
                color: white;
                padding: 4px 6px;
                border-radius: 2px;
                transform: translateY(-2px);
            }
        }
        p{
            margin-top: 24px;
            font-size: 24px;
            font-weight: 400;
            line-height: 24px;
            color: #3C454E;
            span{
                margin-left: 24px;
            }

        }
    }
}
.course-content{
    width: 678px;
    margin: 12px auto 0;
    background: #FFF6F0;
    border-radius: 20px;
    &>img{
        width: 678px;
    }
}
.course-bottom-btn{
    padding: 28px 32px 32px;
    div{
        width: 144px;
        height: 56px;
        background: linear-gradient(90deg, #FF8639 0%, #FD5239 100%);
        border-radius: 8px;
        color: #FFFFFF;
        font-size: 24px;
    }
}
.bold{
    font-weight: bold;
}
.price{
    margin-right: 24px;
}
.price .t1{
    color: #F03C18;
    line-height: 24px;
    font-size: 24px;
    font-family: BebasNeueBold;
    //position: relative;
    //top: 4px;
}
.price .t2{
    color: #F03C18;
    line-height: 28px;
    font-size: 40px;
    font-family: BebasNeueBold;
    margin-right: 4px;
    //position: relative;
    //top: 4px;
}
.price .t3{
    color: #3C454E;
    line-height: 22px;
    font-size: 22px;
}
.price .t4{
    color: #3C454E;
    line-height: 24px;
    font-size: 24px;
    margin-left: 24px;
}
</style>
